<template>
    <el-tabs :class="mode ? 'mode__lockday' : 'mode__locknight'" :tab-position="tabPosition">
        <el-tab-pane v-if="lockPassword.length > 1" :label="$t('message.auto_lock')">
            <el-card :class="mode ? 'el__cardday' : 'el__cardnight'">
                <el-col :span="12">
                    <el-form label-position="left" :model="settings" label-width="180px" size="mini">
                        <div>
                            <h5 :class="mode ? 'text__day2' : 'text__night2'">{{ $t('message.auto_lock_when_inactive') }}</h5>
                        </div>
                        <div>
                            <el-radio-group :class="mode ? 'mode__radioday' : 'mode__radionight'" v-model="secTimer">
                                <el-radio :label="0">{{ $t('message.turned_off') }}</el-radio>
                                <el-radio :label="10000">{{ $t('message.numner_of_secunds', {number: 10}) }}</el-radio>
                                <el-radio :label="600000">{{ $t('message.numner_of_minutes', {number: 10}) }}</el-radio>
                                <el-radio :label="3600000">{{ $t('message.numner_of_hours', {number: 1}) }}</el-radio>
                                <el-radio :label="18000000">{{ $t('message.numner_of_hours', {number: 5}) }}</el-radio>
                            </el-radio-group>
                        </div>
                        <el-button class="my-4" @click="updateTimer()" type="primary">{{ $t('message.save') }}</el-button>
                    </el-form>
                </el-col>
            </el-card>
        </el-tab-pane>
        <el-tab-pane  :label="$t('message.passcode')">
            <el-card :class="mode ? 'el__cardday' : 'el__cardnight'">
                <el-col :span="10">
                    <el-form label-position="left" :model="ruleForm" status-icon :rules="rules" ref="ruleForm" label-width="180px" size="medium" class="demo-ruleForm">
                        <div class="mb-2">
                            <h5 :class="mode ? 'text__day2' : 'text__night2'">{{ $t('message.password') }}</h5>
                        </div>
                        <el-form-item v-if="lockPassword.length > 1" class="mt-2" prop="oldPassword" :label-width="'0px'">
                            <el-input :class="mode ? 'mode_1' : 'mode__2'" :placeholder="$t('message.enter_your_passcode')" v-model="ruleForm.oldPassword" show-password></el-input>
                        </el-form-item>
                        <el-form-item class="label__error" prop="password" :label-width="'0px'">
                            <el-input :class="mode ? 'mode_1' : 'mode__2'" :placeholder="$t('message.enter_new_password')" v-model="ruleForm.password" show-password></el-input>
                        </el-form-item>
                        <el-form-item class="label__error" prop="confirmPassword" :label-width="'0px'">
                            <el-input :class="mode ? 'mode_1' : 'mode__2'" :placeholder="$t('message.repeat_passcode')" v-model="ruleForm.confirmPassword" show-password></el-input>
                        </el-form-item>
                        <el-button class="mb-4 mt-2" @click="setLockPass('ruleForm')" type="primary">{{ $t('message.save') }}</el-button>
                    </el-form>
                </el-col>
            </el-card>
        </el-tab-pane>

    </el-tabs>
</template>

<script>
    import {mapActions, mapGetters} from 'vuex';
    import LockScreenMixin  from '../../utils/mixins/LockScreenMixin'
    const bcrypt = require('bcryptjs')
    const salt = bcrypt.genSaltSync(10)

    export default {
        mixins: [LockScreenMixin],
        data(){
            var validatePass = (rule, value, callback) => {
                if (value === '') {
                callback(new Error(this.$t('message.please_enter_input', {input: this.$t('message.password')})));
                } else {
                if (this.ruleForm.password !== '') {
                    this.$refs.ruleForm.validateField('checkPass');
                }
                callback();
                }
            };
            var validatePass2 = (rule, value, callback) => {
                if (value === '') {
                callback(new Error(this.$t('message.enter_your_password_again')));
                } else if (value !== this.ruleForm.confirmPassword) {
                callback(new Error(this.$t('message.password_does_not_match')));
                } else {
                callback();
                }
            };
            return {
                tabPosition: 'left',
                secTimer: 60,
                is_can_reload: false,
                correct: false,
                ruleForm: {
                    oldPassword: '',
                    password: '',
                    confirmPassword: '',
                },
                rules: {
                    password: [
                        { validator: validatePass, trigger: 'blur' }
                    ],
                    confirmPassword: [
                        { validator: validatePass2, trigger: 'blur' }
                    ]
                }
            }
        },
        watch: {
            password() {
                this.correct = false
            },
            oldPassword() {
                this.correct = false
            },
            confirmPassword () {
                this.correct = false
            }
        },
        mounted () {
            this.secTimer = this.timer
        },
        computed: {
            ...mapGetters({
                timer: 'getLockTimer',
                test: 'getLockBool',
                mode: 'MODE'
            }),
        },
        methods:{
            lockUser(){
                // set lock password if it's not set
                if(this.lockPassword === ''){
                    this.showSetLockPasswordModal = true;
                } else {
                    this.TRIGGER_LOCK({ status: true });
                }
            },
            updateTimer () {
                this.$store.commit('setLockTimer', this.secTimer)
                this.is_can_reload = true
                if (this.is_can_reload) {
                    location.reload();
                }
            },
            setLockPass(formName){
                this.$refs[formName].validate((valid) => {
                if (valid) {
                    if (this.lockPassword) {
                        if (bcrypt.compareSync(this.ruleForm.oldPassword, this.lockPassword)) {
                            this.UPDATE_PASSWORD({
                                password: bcrypt.hashSync(this.ruleForm.password, salt)
                            });
                            this.ruleForm.password = ''
                            this.ruleForm.oldPassword = ''
                            this.ruleForm.confirmPassword = ''
                            this.$notify({
                                title: this.$t('message.succesfully'),
                                message: this.$t('message.succesfully'),
                                type: 'success',
                            });
                        } else {

                        }
                    } else {
                        this.UPDATE_PASSWORD({
                            password: bcrypt.hashSync(this.ruleForm.password, salt)
                        });
                        this.ruleForm.password = ''
                        this.ruleForm.oldPassword = ''
                        this.ruleForm.confirmPassword = ''
                        this.$notify({
                            title: this.$t('message.succesfully'),
                            message: this.$t('message.succesfully'),
                            type: 'success'
                        });

                    }
                } else {
                    console.log('error submit!!');
                    return false;
                }
                });

            }
        }
    }
</script>
<style>
    .el-select {
        width: 100% !important;
    }
    .border__notcorrect input {
        border-color: crimson !important;
    }
    .border__notcorrect input:hover {
        border-color: rgb(182, 0, 36) !important;
    }
    .label__error .el-form-item__error {
        padding-top: 12px !important;
    }
.text__night {
    color: #000;
}
.mode__radionight .el-radio__inner {
    background: inherit !important;
    border: 1px solid inherit !important;
}
.mode__radionight .el-radio {
    color: #fff !important;
}
.mode__locknight .el-tabs__item {
    color: #fff;
}
.el-tabs__item.is-active {
    color: #409EFF !important;
}
.label__night label {
    color: #fff !important;
}
.checkbox__night .el-checkbox__inner {
    background: inherit;
}
</style>
